export const currencyFormatterDecimal = (val: number) => {
	const roundVal = Math.floor(val * 100) / 100;
	const newVal = roundVal.toFixed(2).replace('.', '.');
	return newVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const currencyFormatter = (val: number) => {
	if (!val) return val;
	return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
