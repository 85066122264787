import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getOrders = async (data: {startDate: string; endDate: string}) => {
	const response = await axiosClient.post(api.PRIVATE.GET_ORDERS, data);
	return response.data;
};

/**
 * Update
 */

const updateOrder = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_ORDER + id, data);
	return response.data;
};

const cancelPreOrder = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_PRE_ORDER + pnr);
	return response.data;
};
const cancelPreOrderHotel = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_PRE_ORDER + pnr);
	return response.data;
};
const cancelPackagePreOrder = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_PACKAGE_PRE_ORDER + pnr);
	return response.data;
};

const cancelBooking = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_BOOKING + pnr);
	return response.data;
};

interface BookingToTicketingParams {
	paymentType: string;
	partialPayment?: string | undefined;
	partialCurrency?: string | undefined;
}

const bookingToTicketing = async (data: BookingToTicketingParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING + pnr, data);
	return response.data;
};

interface RefundTicketParams {
	placesDepartureClass: string;
	placesReturnClass?: string;
	commissionInfo: string;
}

const refundTicket = async (data: RefundTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_TICKET + pnr, data);
	return response.data;
};

interface SplitTicketParams {
	ticketNumber: string;
}

const splitTicket = async (data: SplitTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.SPLIT_TICKET + pnr, data);
	return response.data;
};

/* ReservHotel */
/* Get */
const getOrdersHotel = async (data: {startDate: string; endDate: string}) => {
	const response = await axiosClient.get(api.PRIVATE.GET_ORDERS_HOTEL /* , {params: data} */);
	return response.data;
};
const updateOrderHotel = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_ORDER_HOTEL + id, data);
	return response.data;
};

const bookingToTicketingHotel = async (data: BookingToTicketingParams, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING_HOTEL + id, data);
	return response.data;
};

const cencelBookingHotel = async (id: string) => {
	const response = await axiosClient.post(api.PRIVATE.CENCEL_BOOKING_HOTEL + id);
	return response.data;
};

const refundHotel = async (data: {commisionInfo: string}, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_HOTEL + id, data);
	return response.data;
};

/* ReservPackage */
/* Get */
const getPackageOrders = async (data: {startDate: string; endDate: string}) => {
	const response = await axiosClient.post(api.PRIVATE.GET_PACKAGE_ORDERS /* , data */);
	return response.data;
};

/* Update */

const updatePackageOrder = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_PACKAGE_ORDER + id, data);
	return response.data;
};

/* Cencel */

const cancelPackageBooking = async (pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CANCEL_PACKAGE_BOOKING + pnr);
	return response.data;
};

const bookingToTicketingPackage = async (data: BookingToTicketingParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.BOOKING_TO_TICKETING_PACKAGE + pnr, data);
	return response.data;
};

const refundPackage = async (data: RefundTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.REFUND_PACKAGE + pnr, data);
	return response.data;
};

const splitPackage = async (data: SplitTicketParams, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.SPLIT_PACKAGE + pnr, data);
	return response.data;
};

const changePrice = async (data: any, pnr: string) => {
	const response = await axiosClient.post(api.PRIVATE.CHANGE_PRICE + pnr, data);
	return response.data;
};

const service = {
	getOrders,
	getOrdersHotel,
	getPackageOrders,
	updatePackageOrder,
	updateOrder,
	cancelPreOrder,
	cancelPackagePreOrder,
	cancelBooking,
	cancelPreOrderHotel,
	cancelPackageBooking,
	bookingToTicketing,
	bookingToTicketingPackage,
	refundTicket,
	refundHotel,
	refundPackage,
	splitTicket,
	changePrice,
	splitPackage,
	bookingToTicketingHotel,
	updateOrderHotel,
	cencelBookingHotel
};

export default service;
