import {useContext} from 'react';
import {
	AppBar,
	Toolbar,
	List,
	ListItem,
	ListItemText,
	Button,
	Link,
	makeStyles,
	useTheme,
	useMediaQuery
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {NavLink} from 'react-router-dom';
import EuroIcon from '@material-ui/icons/Euro';
import FacebookIcon from 'assets/icons/facebook.jpg';
import Telegram from 'assets/icons/telegram.jpg';
import InstagramIcon from 'assets/icons/instagram.jpg';
import LinkedinIcon from 'assets/icons/linkedIn.jpg';
import PopoverComponent from 'components/PopoverComponent';
import {AuthContext} from 'context/AuthContext';
import {ProfileContext} from 'context/ProfileContext';
import {currencyFormatterDecimal} from 'helpers/currency.helper';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import ROLES from 'constants/role.contstants';
import AMD from 'assets/icons/AMD.png';
import {ROLE_TYPE} from 'App';
import logoW from 'assets/logowhite.png';

export const social = [
	{
		icon: FacebookIcon,
		url: 'https://facebook.com/festatour/'
	},
	{
		icon: InstagramIcon,
		url: 'https://www.instagram.com/festatour/'
	},
	{
		icon: LinkedinIcon,
		url: 'https://www.linkedin.com/company/festatour/'
	},
	{
		icon: Telegram,
		url: 'https://t.me/festatour/'
	}
];

export const Social = () => {
	const localSocial = [...social];
	return (
		<>
			{localSocial.map((el, i: number) => (
				<Link key={i} href={el.url} underline="none" color="inherit" target="_blank">
					<img src={el.icon} alt={'icon'} width="30px" style={{marginRight: 13}} />
				</Link>
			))}
		</>
	);
};

const useStyles = makeStyles(theme => ({
	container: {
		boxShadow: 'unset'
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#fff',
		paddingRight: 40,
		paddingLeft: 24
	},
	balance: {
		display: 'flex',
		fontSize: 16,
		fontWeight: 500,
		marginRight: 10
	},
	leftBlock: {
		display: 'flex',
		'& a': {
			marginRight: 10,
			display: 'flex',
			alignItems: 'center'
		},
		'& hr': {
			margin: '0 12px 0 5px'
		}
	},
	rightBlock: {
		display: 'flex',
		alignItems: 'center'
	},
	socialButton: {
		color: 'inherit'
	},
	menuItem: {
		background: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		height: 'fit-content',
		padding: '10px 30px',
		'&:hover': {
			background: theme.palette.primary.light
		}
	}
}));

const AppBarComponent = () => {
	const classes = useStyles();
	const history = useHistory();
	const {dispatch: authDispatch} = useContext(AuthContext);
	const {state: profileState} = useContext(ProfileContext);
	const userInfo = profileState.companyName + ' - ' + profileState.contactNameSurname;
	const theme = useTheme();

	const handleLogout = () => {
		authDispatch({
			type: 'LOGOUT'
		});
	};

	return (
		<AppBar color="primary" position="static" className={classes.container}>
			<Toolbar className={classes.toolbar} variant="dense">
				<NavLink to="/">
					<img src={logoW} alt="" width="90px" /*  height="100px" */ />
				</NavLink>
				<div className={classes.rightBlock}>
					<div className={classes.balance}>
						Balance: <img src={AMD} alt="amd" height="16" style={{margin: '2px 10px 0 15px'}} />{' '}
						{currencyFormatterDecimal(profileState.balance)}
					</div>

					<Button component={RouterLink} to={'/currency'} color="inherit">
						<div
							style={{
								display: 'flex',
								flexDirection: useMediaQuery(theme.breakpoints.down('md')) ? 'column' : 'row',
								justifyContent: 'center',
								alignItems: 'center',
								width: 'max-content',
								height: 'max-content',
								fontSize: 16
							}}
							color="inherit">
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<AttachMoneyIcon fontSize="small" />
								{profileState.usdRate}
							</div>
							<div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
								<EuroIcon fontSize="small" style={{marginRight: 3}} />
								{profileState.eurRate}
							</div>
						</div>
					</Button>

					<PopoverComponent title={userInfo}>
						<List component="nav">
							{!(ROLE_TYPE === ROLES.AGENT || ROLE_TYPE === ROLES.USER_REPORTS) && (
								<ListItem button onClick={() => history.push(`/settings`)}>
									<ListItemText primary="Settings" />
								</ListItem>
							)}
							<ListItem button onClick={() => history.push(`/profile`)}>
								<ListItemText primary="Profile" />
							</ListItem>
							<ListItem
								button
								onClick={() => {
									handleLogout();
									history.push(`/`);
								}}>
								<ListItemText primary="Log out" />
							</ListItem>
						</List>
					</PopoverComponent>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default AppBarComponent;
