import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getProfile = async () => {
	const response = await axiosClient.get(api.PRIVATE.PROFILE_API);
	return response.data;
};

const service = {
	getProfile
};

export default service;
