import {useMemo, forwardRef} from 'react';
import {ListItem, ListItemIcon, ListItemText, makeStyles} from '@material-ui/core';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {ROLE_TYPE} from 'App';

const useStyles = makeStyles(theme => ({
	listIcon: {
		minWidth: 30
	},
	menuItem: {
		'& .MuiTypography-root': {
			fontWeight: 'normal',
			fontSize: 16,
			fontStyle: 'normal'
		},
		'&.active  .MuiTypography-root': {
			color: theme.palette.primary.main,
			fontWeight: 600
		},
		'&.active .MuiListItemIcon-root': {
			color: theme.palette.secondary.main
		}
	}
}));

interface ListItemLinkProps {
	icon?: React.ReactElement;
	primary: React.ReactElement | string;
	to?: string;
	onClick?: (val: any) => void;
	disable?: boolean;
	disableForRoles?: string[];
}

const ListItemLink = (props: ListItemLinkProps) => {
	const {icon, primary, to, onClick, disable, disableForRoles} = props;
	const classes = useStyles();

	const renderLink = useMemo(
		() =>
			forwardRef<any, Omit<NavLinkProps, 'to'>>((itemProps, ref) =>
				to ? (
					<NavLink
						to={to}
						ref={ref}
						{...itemProps}
						onClick={onClick}
						style={{opacity: primary === 'Reservations' || primary === 'Reports' ? 1 : ''}}
					/>
				) : null
			),
		[to, onClick]
	);

	return (
		<li style={{cursor: 'pointer'}}>
			<ListItem
				button
				{...(to ? {component: renderLink} : {})}
				className={classes.menuItem}
				disabled={disable || disableForRoles?.includes(ROLE_TYPE)}>
				{icon ? <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
};

export default ListItemLink;
