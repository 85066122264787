import {Container, Typography, Avatar, Paper, makeStyles} from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		padding: theme.spacing(2, 1, 5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center'
	},
	avatar: {
		margin: theme.spacing(1, 1, 4),
		backgroundColor: theme.palette.error.main,
		width: theme.spacing(7),
		height: theme.spacing(7)
	}
}));

const errorText = 'Տեղի է ունեցել սխալ';

const WarningContainer = ({warningText = errorText}: {warningText: string}) => {
	const classes = useStyles();
	return (
		<Container maxWidth="xs">
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<Warning />
				</Avatar>
				<Typography component="h1" variant="h5">
					{warningText}
				</Typography>
			</Paper>
		</Container>
	);
};

export default WarningContainer;
