import {useEffect, createContext, useReducer, Dispatch, ReactNode} from 'react';

const initialState = {
	isAuthenticated: undefined,
	userId: null,
	token: null
};

export interface StateProps {
	isAuthenticated: boolean | undefined;
	userId: null | string;
	token: null | string;
}

type Action = {type: 'LOGIN'; payload: {userId: string; token: string}} | {type: 'LOGOUT'};

interface InitContextProps {
	state: StateProps;
	dispatch: Dispatch<Action>;
}

export const AuthContext = createContext({} as InitContextProps);

const reducer = (state: StateProps, action: Action) => {
	switch (action.type) {
		case 'LOGIN':
			localStorage.setItem('userId', action.payload.userId);
			localStorage.setItem('token', action.payload.token);
			return {
				...state,
				isAuthenticated: true,
				userId: action.payload.userId,
				token: action.payload.token
			};
		case 'LOGOUT':
			localStorage.clear();
			return {
				...state,
				isAuthenticated: false,
				userId: null,
				token: null
			};
		default:
			return state;
	}
};

const AuthProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	useEffect(() => {
		const token = localStorage.getItem('token');
		const userId = localStorage.getItem('userId');
		if (token && userId) {
			dispatch({
				type: 'LOGIN',
				payload: {
					userId,
					token
				}
			});
		} else {
			dispatch({
				type: 'LOGOUT'
			});
		}
	}, []);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
