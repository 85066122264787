import {Redirect, Route, RouteProps} from 'react-router-dom';
import {StateProps} from 'context/AuthContext';

interface PrivateRouteProps extends RouteProps {
	authState: StateProps;
}

const PrivateRoute = ({component: Component, authState, ...rest}: PrivateRouteProps) => {
	return (
		<Route
			{...rest}
			render={props =>
				authState.isAuthenticated ? (
					Component && <Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: {from: props.location}
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
