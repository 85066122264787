import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getFlightReports = async (data?: any) => {
	const response = await axiosClient.post(api.PRIVATE.FLIGHT_REPORTS, data);
	return response.data.result;
};

const service = {
	getFlightReports
};

export default service;
