import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface AgentsProps {
	role?: string;
	status: string;
}

const getAgents = async (data?: AgentsProps) => {
	const response = await axiosClient.post(api.PRIVATE.GET_AGENTS, data);
	return response.data.result;
};

const getAgentsByID = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_AGENTS_BY_ID + id);
	return response.data;
};

const getBalanceHistoryByID = async (id: string) => {
	const response = await axiosClient.post(api.PRIVATE.GET_BALANCE_HISTORY + id);
	return response.data;
};

/**
 * Update
 */

const updateAgent = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.UPDATE_AGENT + id, data);
	return response.data;
};

const addBalance = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.ADD_BALANCE + id, data);
	return response.data;
};

const useBalance = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.USE_BALANCE + id, data);
	return response.data;
};

const changePassword = async (data: any, id: string) => {
	const response = await axiosClient.post(api.PRIVATE.CHANGE_PASSWORD + id, data);
	return response.data;
};

const service = {
	getAgents,
	getAgentsByID,
	getBalanceHistoryByID,
	changePassword,
	updateAgent,
	addBalance,
	useBalance
};

export default service;
