import PUBLIC from './public.api';
import PRIVATE from './private.api';
import EMAIL from './email.api';
import PDF from './pdf.api';

const APP_API = {
	PUBLIC,
	PRIVATE,
	EMAIL,
	PDF
};

export default APP_API;
