import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface GetAirportsParams {
	flightStatus: string;
}

const getAirports = async (data?: GetAirportsParams) => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRPORTS, {params: data});
	return response.data;
};

const getAirlines = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRLINES);
	return response.data;
};

const getAircrafts = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_AIRCRAFTS);
	return response.data;
};

const getHotelProviders = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOTEL_PROVIDERS);
	return response.data;
};

/**
 * CREATE
 */
interface CreateAirportParams {
	country: string;
	city: string;
	airport: string;
	iata: string;
}

const createAirport = async (data: CreateAirportParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRPORTS, data);
	return response.data;
};

interface CreateAirlineParams {
	name: string;
	iata: string;
	icao: string;
}

const createAirline = async (data: CreateAirlineParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRLINES, data);
	return response.data;
};

interface CreateAircraftParams {
	name: string;
	seats: string;
}

const createAircraft = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_AIRCRAFT, data);
	return response.data;
};

const createHotelProviders = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.HOTEL_PROVIDERS, data);
	return response.data;
};

const createRoomCategory = async (data: CreateAircraftParams) => {
	const response = await axiosClient.post(api.PRIVATE.GET_ROOM_CATEGORY, data);
	return response.data;
};

/* Template */
/* get */

const getFlightTemplates = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_TEMPLATES);
	return response.data;
};

const getFlightTemplateById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_TEMPLATES + id);
	return response.data;
};

const getFlightClassTemplates = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FLIGHT_CLASS_TEMPLATES);
	return response.data;
};
const getBaggageRules = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_BAGGAGE_RULES);
	return response.data;
};
const getFareRules = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_FARE_RULES);
	return response.data;
};
const getCompanySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_COMPANY_SETTINGS);
	return response.data;
};
const getNotifications = async () => {
	const response = await axiosClient.get(api.PRIVATE.NOTIFICATIONS);
	return response.data;
};
const getSMTPSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_SMTP_SETTINGS);
	return response.data;
};
const getTicketSettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TICKET_SETTINGS);
	return response.data;
};

const getTimeLimits = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_TIME_LIMITS);
	return response.data;
};
const getManagersList = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_MANAGERS_LIST);
	return response.data;
};

const getCurrencySettings = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY);
	return response.data;
};

const getCurrencyMarginTypes = async () => {
	const response = await axiosClient.get(api.PRIVATE.CURRENCY_MARGIN_TYPES);
	return response.data;
};

/* create */

interface CreateFlightTemplatesParams {
	templateName: string;
	departureDate: string;
	flightNumber: string;
	arrivalDate: string;
	departureAirport: string;
	arrivalAirport: string;
	airline: string;
	duration: string;
	aircrafts: string;
	bookingTime: string;
	currency: string;
	ticketCode: string;
}

const createFlightTemplates = async (data: CreateFlightTemplatesParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT_TEMPLATES, data);
	return response.data;
};

interface CreateFlightClassTemplatesParams {
	onlyForAdmin: boolean;
	templateName: string;
	class: string;
	seats: number;
	avSeats: number;
	classType: string;
	travelType: string;
	numberOfSeats: number;
	fareAdult: number;
	fareChd: number;
	fareInf: number;
	taxAdult: number;
	taxChd: number;
	cat: number;
	multi: number;
	long: number;
	short: number;
	commAdult: number;
	commChd: number;
}

const createFlightClassTemplates = async (data: CreateFlightClassTemplatesParams) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FLIGHT_CLASS_TEMPLATES, data);
	return response.data;
};
const createBaggageRule = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_BAGGAGE_RULE, data);
	return response.data;
};
const createFareRule = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_FARE_RULE, data);
	return response.data;
};
const createNotifications = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.NOTIFICATIONS, data);
	return response.data;
};
const createSMTPSettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_SMTP_SETTING, data);
	return response.data;
};

const createTicketSettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TICKET_SETTING, data);
	return response.data;
};

const createCompanySettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_COMPANY_SETTING, data);
	return response.data;
};

const createTimeLimits = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_TIME_LIMITS, data);
	return response.data;
};

const createCurrencySettings = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CURRENCY, data);
	return response.data;
};
const createManager = async (data: any) => {
	const response = await axiosClient.post(api.PRIVATE.CREATE_MANAGER, data);
	return response.data;
};

/* update */

const updateAirports = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRPORTS + id, data);
	return response.data;
};

const updateAirline = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRLINE + id, data);
	return response.data;
};
const updateAircraft = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_AIRCRAFT + id, data);
	return response.data;
};

const updateFlightTemplate = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FLIGHT_TEMPLATE + id, data);
	return response.data;
};

const updateFlightClassTemplate = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FLIGHT_CLASS_TEMPLATES + id, data);
	return response.data;
};

const updateManager = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_MANAGER + id, data);
	return response.data;
};
const updateCompanySettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_COMPANY_SETTINGS, data);
	return response.data;
};

const updateSMTPSettings = async (data: any) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_SMTP_SETTING, data);
	return response.data;
};

const updateNotifications = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.NOTIFICATIONS + id, data);
	return response.data;
};

const updateTimeLimits = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TIME_LIMITS + id, data);
	return response.data;
};

const updateCurrencySettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.CURRENCY + id, data);
	return response.data;
};

const updateTicketSettings = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_TICKET_SETTING + id, data);
	return response.data;
};
const updateBaggageRules = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_BAGGAGE_RULE + id, data);
	return response.data;
};

const updateFareRules = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_FARE_RULE + id, data);
	return response.data;
};

const updateProvider = async (data: any, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.UPDATE_PROVIDERS + id, data);
	return response.data;
};

/**
 * Delete
 */

const deleteFlightTemplate = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FLIGHT_TEMPLATE + id);
	return response.data;
};

const deleteFlightClassTemplates = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FLIGHT_CLASS_TEMPLATES + id);
	return response.data;
};

const deleteAircraft = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRCRAFT + id);
	return response.data;
};

const deleteAirline = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRLINE + id);
	return response.data;
};

const deleteAirport = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_AIRPORT + id);
	return response.data;
};

const deleteBaggageRule = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_BAGGAGE_RULE + id);
	return response.data;
};

const deleteFareRule = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_FARE_RULE + id);
	return response.data;
};

const deleteHotelProvider = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_HOTEL_PROVIDER + id);
	return response.data;
};

const deleteRoomCategory = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_ROOM_CATEGORY + id);
	return response.data;
};

const deleteNotification = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.NOTIFICATIONS + id);
	return response.data;
};

const deleteCurrencySettings = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.CURRENCY + id);
	return response.data;
};

const deleteManager = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.DELETE_MANAGER + id);
	return response.data;
};

const service = {
	getFlightTemplates,
	getFlightTemplateById,
	getFlightClassTemplates,
	getAirports,
	getBaggageRules,
	getFareRules,
	getCompanySettings,
	getTicketSettings,
	getSMTPSettings,
	getNotifications,
	getAirlines,
	getHotelProviders,
	getAircrafts,
	getManagersList,
	getTimeLimits,
	getCurrencyMarginTypes,
	getCurrencySettings,

	createAirport,
	createAirline,
	createAircraft,
	createHotelProviders,
	createRoomCategory,
	createBaggageRule,
	createFareRule,
	createFlightClassTemplates,
	createFlightTemplates,
	createTicketSettings,
	createNotifications,
	createCompanySettings,
	createSMTPSettings,
	createManager,
	createTimeLimits,
	createCurrencySettings,

	updateAirports,
	updateFlightClassTemplate,
	updateFlightTemplate,
	updateManager,
	updateCompanySettings,
	updateSMTPSettings,
	updateNotifications,
	updateTimeLimits,
	updateCurrencySettings,
	updateTicketSettings,
	updateAirline,
	updateAircraft,
	updateBaggageRules,
	updateFareRules,
	updateProvider,

	deleteFlightClassTemplates,
	deleteFlightTemplate,
	deleteAircraft,
	deleteAirline,
	deleteAirport,
	deleteBaggageRule,
	deleteFareRule,
	deleteHotelProvider,
	deleteRoomCategory,
	deleteNotification,
	deleteManager,
	deleteCurrencySettings
};

export default service;
