import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get user groups for register
 */
const getUserGroupList = async () => {
	const response = await axiosClient.get(api.PUBLIC.GET_USER_GROUPS_LIST);
	return response.data;
};

/**
 * Create user
 */
export interface CreateUserParams {
	name: string;
	address: string;
	hvhh: string;
	email: string;
	telephone: string;
	password: string;
	c_password: string;
}

const createUser = async (data: CreateUserParams) => {
	const response = await axiosClient.post(api.PUBLIC.CREATE_USER, data);
	return response.data;
};

const service = {
	getUserGroupList,
	createUser
};

export default service;
