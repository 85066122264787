import AUTH from './auth.service';
import USER from './user.service';
import EMAIL from './email.service';
import EXCHANGE from './exchange.service';
import PDF from './pdf.service';

const PUBLIC_SERVICES = {
	AUTH,
	USER,
	EMAIL,
	EXCHANGE,
	PDF
};

export default PUBLIC_SERVICES;
