import {Avatar, Typography, makeStyles} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
	successAlert: {
		padding: theme.spacing(1, 1, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center'
	},
	avatar: {
		margin: theme.spacing(1, 1, 5),
		backgroundColor: theme.palette.success.main,
		width: theme.spacing(7),
		height: theme.spacing(7)
	},
	btn: {
		marginTop: theme.spacing(5)
	}
}));

const SuccessAlert = ({text}: {text: string | null}) => {
	const classes = useStyles();
	return (
		<div className={classes.successAlert}>
			<Avatar className={classes.avatar}>
				<CheckCircle />
			</Avatar>
			<Typography component="h1" variant="h5">
				{text}
			</Typography>
		</div>
	);
};

export default SuccessAlert;
