import React from 'react';
import type {RouteProps} from 'react-router-dom';
import ROLES from 'constants/role.contstants';

interface PrivateRouteProps extends RouteProps {
	name: string;
	role: string[];
	icon?: React.ReactElement;
	navBar?: boolean;
	disable?: boolean;
	disableForRoles?: string[];
	subMenu?: PrivateRouteProps[];
}

export const privateRoutes: Array<PrivateRouteProps> = [
	{
		path: '/search',
		name: 'Search',
		component: React.lazy(() => import('./pages/privatePages/Search')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_PACKAGES
		],
		navBar: true
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: React.lazy(() => import('./pages/privatePages/Checkout')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	},
	{
		path: '/reservations/flight',
		name: 'Reservations',
		component: React.lazy(() => import('./pages/privatePages/Reservations/ReservationFlight')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		],
		navBar: true,
		exact: true
		// disable: true,
		// subMenu: [
		// 	{
		// 		path: '/reservations/flight',
		// 		name: 'Flights',
		// 		component: React.lazy(() => import('./pages/privatePages/Reservations/ReservationFlight')),
		// 		role: [
		// 			ROLES.SUPER_ADMIN,
		// 			ROLES.ADMIN,
		// 			ROLES.AGENT,
		// 			ROLES.VIEWER,
		// 			ROLES.ADMIN_TICKETS,
		// 			ROLES.ADMIN_PACKAGES
		// 		]
		// 	},
		// 	{
		// 		path: '/reservations/hotel',
		// 		name: 'Hotels',
		// 		component: React.lazy(() => import('./pages/privatePages/Reservations/ReservationHotel')),
		// 		role: [
		// 			ROLES.SUPER_ADMIN,
		// 			ROLES.ADMIN,
		// 			ROLES.AGENT,
		// 			ROLES.VIEWER,
		// 			ROLES.ADMIN_HOTELS,
		// 			ROLES.ADMIN_PACKAGES
		// 		]
		// 	},
		// 	{
		// 		path: '/reservations/package',
		// 		name: 'Packages',
		// 		component: React.lazy(() => import('./pages/privatePages/Reservations/ReservationPackage')),
		// 		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.AGENT, ROLES.VIEWER, ROLES.ADMIN_PACKAGES]
		// 	}
		// ]
	},
	{
		path: '/reservation/edit/:id',
		name: 'Reservation Edit',
		component: React.lazy(() => import('./pages/privatePages/Reservations/EditReservation')),
		exact: true,
		navBar: false,
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_PACKAGES,
			ROLES.AGENT,
			ROLES.ADMIN_HOTELS
		]
	},
	{
		path: '/flights',
		name: 'Flights',
		component: React.lazy(() => import('./pages/privatePages/Flights')),
		exact: true,
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS, ROLES.ADMIN_PACKAGES],
		navBar: true
	},
	{
		path: '/flights/:id',
		name: 'Flight Classes',
		component: React.lazy(() => import('./pages/privatePages/FlightClasses')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_TICKETS, ROLES.ADMIN_PACKAGES]
	},
	// {
	// 	path: '/hotels',
	// 	name: 'Hotels',
	// 	component: React.lazy(() => import('./pages/privatePages/Hotels')),
	// 	role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_HOTELS, ROLES.ADMIN_PACKAGES],
	// 	navBar: true,
	// 	exact: true
	// },
	{
		path: '/hotels/room_type/:id',
		name: 'Room Type',
		component: React.lazy(() => import('./pages/privatePages/Rooms')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_HOTELS, ROLES.ADMIN_PACKAGES]
	},
	// {
	// 	path: '/hotelsSearch/:id',
	// 	name: 'Hotel Description',
	// 	component: React.lazy(() => import('./pages/privatePages/HotelDescription')),
	// 	role: [
	// 		ROLES.SUPER_ADMIN,
	// 		ROLES.ADMIN,
	// 		ROLES.AGENT,
	// 		ROLES.VIEWER,
	// 		ROLES.ADMIN_HOTELS,
	// 		ROLES.ADMIN_PACKAGES
	// 	]
	// },
	// {
	// 	path: '/services',
	// 	name: 'Services',
	// 	component: React.lazy(() => import('./pages/privatePages/Services')),
	// 	role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.ADMIN_HOTELS, ROLES.ADMIN_PACKAGES],
	// 	navBar: true
	// },
	{
		path: '/agents',
		name: 'Agents',
		component: React.lazy(() => import('./pages/privatePages/Agents')),
		exact: true,
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		],
		navBar: true
	},
	{
		path: '/agents/:id',
		name: 'Agent Info',
		component: React.lazy(() => import('./pages/privatePages/AgentBalance')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	},
	{
		path: '/history',
		name: 'History',
		component: React.lazy(() => import('./pages/privatePages/History')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		],
		navBar: true
	},
	{
		path: '/reports/flight',
		name: 'Reports',
		component: React.lazy(() => import('./pages/privatePages/Reports/ReportFlight')),
		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.USER_REPORTS],
		navBar: true,
		exact: true
		// disable: true,
		// subMenu: [
		// 	{
		// 		path: '/reports/flight',
		// 		name: 'Flights',
		// 		component: React.lazy(() => import('./pages/privatePages/Reports/ReportFlight')),
		// 		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.USER_REPORTS]
		// 	},
		// 	{
		// 		path: '/reports/hotel',
		// 		name: 'Hotels',
		// 		component: React.lazy(() => import('./pages/privatePages/Reports/ReportHotel')),
		// 		role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.USER_REPORTS],
		// 		disable: true
		// 	}
		// 	// {
		// 	// 	path: '/reports/package',
		// 	// 	name: 'Packages',
		// 	// 	component: React.lazy(() => import('./pages/privatePages/Reports/ReportPackage')),
		// 	// 	role: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.VIEWER, ROLES.USER_REPORTS],
		// 	// 	disable: true
		// 	// }
		// ]
	},
	{
		path: '/settings',
		name: 'Settings',
		component: React.lazy(() => import('./pages/privatePages/Settings')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	},
	{
		path: '/profile',
		name: 'Profile',
		component: React.lazy(() => import('./pages/privatePages/Profile')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	},
	{
		path: '/currency',
		name: 'Currency',
		component: React.lazy(() => import('./pages/privatePages/Currency')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.USER_REPORTS,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	},
	{
		path: '/confirm',
		name: 'Confirm Ticket Status',
		component: React.lazy(() => import('./pages/privatePages/Checkout/ConfirmTicketStatus')),
		role: [
			ROLES.SUPER_ADMIN,
			ROLES.ADMIN,
			ROLES.AGENT,
			ROLES.VIEWER,
			ROLES.ADMIN_TICKETS,
			ROLES.ADMIN_HOTELS,
			ROLES.ADMIN_PACKAGES
		]
	}
];

export const publicRoutes: Array<RouteProps> = [
	{
		path: '/',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/login',
		component: React.lazy(() => import('./pages/publicPages/Login')),
		exact: true
	},
	{
		path: '/register',
		component: React.lazy(() => import('./pages/publicPages/Register')),
		exact: true
	},
	{
		path: '/reset',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword')),
		exact: true
	},
	{
		path: '/resetVerification',
		component: React.lazy(() => import('./pages/publicPages/ResetPassword/CreateNewPassword')),
		exact: true
	},
	{
		path: '/verification',
		component: React.lazy(() => import('./pages/publicPages/RegisterVerification')),
		exact: true
	}
];
