import {CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	loading: {
		position: 'absolute',
		top: 'calc(50% - 20px)',
		left: 'calc(50% - 20px)'
	}
}));

const Loading = () => {
	const classes = useStyles();
	return <CircularProgress className={classes.loading} />;
};

export default Loading;
