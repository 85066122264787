import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getServices = async () => {
	const response = await axiosClient.get(api.PRIVATE.SERVICES);
	return response.data;
};

interface ServiceTypes {
	name: string;
	type: string;
	description: string;
	countryId: string;
	price: number;
	currency: string;
	paymentType: string;
}

/**
 * Create
 */

const createService = async (data: ServiceTypes) => {
	const response = await axiosClient.post(api.PRIVATE.SERVICES, data);
	return response.data;
};

/**
 * Update
 */

const updateService = async (data: ServiceTypes, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.SERVICES + '/' + id, data);
	return response.data;
};

/**
 * Delete
 */

const deleteService = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.SERVICES + '/' + id);
	return response.data;
};

const service = {
	getServices,
	createService,
	updateService,
	deleteService
};

export default service;
