import {createContext, useReducer, Dispatch, ReactNode} from 'react';

const initialState = {
	ceoNameSurname: '',
	address: '',
	businessName: '',
	vat: '',
	country: '',
	city: '',
	status: '',
	mobileNmber: '',
	balance: 0,
	companyName: '',
	contactNameSurname: '',
	phone: '',
	email: '',
	name: '',
	userId: '',
	usdRate: '',
	eurRate: '',
	role: '',
	privilege: '',
	adminGroups: ''
};

export interface ProfileProps {
	ceoNameSurname: string;
	address: string;
	businessName: string;
	vat: string;
	country: string;
	city: string;
	status: string;
	mobileNmber: string;
	balance: number;
	companyName: string;
	contactNameSurname: string;
	sputnikManager?: string;
	phone: string;
	email: string;
	name: string;
	userId: string;
	usdRate: string;
	eurRate: string;
	role: string;
	privilege: string;
	adminGroups?: string;
}

type Action =
	| {
			type: 'PROFILE';
			payload: {
				ceoNameSurname: string;
				address: string;
				businessName: string;
				vat: string;
				country: string;
				city: string;
				status: string;
				mobilePhone: string;
				balance: any;
				companyName: string;
				contactNameSurname: string;
				phone: string;
				email: string;
				name: string;
				userId: string;
				role: string;
				privilege: string;
				adminGroups?: string;
			};
	  }
	| {type: 'EXCHANGE'; payload: any};

interface InitContextProps {
	state: ProfileProps;
	dispatch: Dispatch<Action>;
}

export const ProfileContext = createContext({} as InitContextProps);

const reducer = (state: ProfileProps, action: Action) => {
	switch (action.type) {
		case 'PROFILE':
			return {
				...state,
				businessName: action.payload.businessName,
				vat: action.payload.vat,
				country: action.payload.country,
				city: action.payload.city,
				status: action.payload.status,
				mobilePhone: action.payload.mobilePhone,
				address: action.payload.address,
				ceoNameSurname: action.payload.ceoNameSurname,
				balance: action.payload.balance.currentBalance,
				companyName: action.payload.companyName,
				contactNameSurname: action.payload.contactNameSurname,
				phone: action.payload.phone,
				email: action.payload.email,
				name: `${action.payload.businessName} (${action.payload.email})`,
				userId: action.payload.userId,
				role: action.payload.role,
				privilege: action.payload.privilege,
				adminGroups: action.payload.adminGroups
			};
		case 'EXCHANGE':
			return {
				...state,
				usdRate: action.payload[0]?.festaRate.USD,
				eurRate: action.payload[0]?.festaRate.EUR
			};
		default:
			return state;
	}
};

const ProfileProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
