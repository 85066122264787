import SEARCH from './search.service';
import PROFILE from './profile.service';
import AGENTS from './agents.service';
import FLIGHTS from './flights.service';
import HISTORY from './history.service';
import RESERVATIONS from './reservations.service';
import SETTINGS from './settings.service';
import SERVICES from './services.service';
import HOTELS from './hotels.service';
import ROOMS from './room.service';
import REPORTS from './reports.service';

const PRIVATE_SERVICES = {
	SEARCH,
	PROFILE,
	AGENTS,
	FLIGHTS,
	HISTORY,
	RESERVATIONS,
	SETTINGS,
	SERVICES,
	HOTELS,
	ROOMS,
	REPORTS
};

export default PRIVATE_SERVICES;
