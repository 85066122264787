import api from 'api';

const APP_API = process.env.REACT_APP_API_URL;
const PDF_API = process.env.REACT_APP_PHP_API_URL;

const createReceiptPDF = async (pnr: string) => {
	const data = {
		pnr,
		url: APP_API
	};
	const url = PDF_API + api.PDF.RECEIPT_PDF + '?data=' + encodeURIComponent(JSON.stringify(data));
	return window.open(url);
};

interface CreateTicketPDFProps {
	id: number;
	address: string;
	pnr: string;
}

const createTicketPDF = async (data: CreateTicketPDFProps) => {
	const newData = {
		...data,
		...{
			url: APP_API
		}
	};
	const url = PDF_API + api.PDF.TICKET_PDF + '?data=' + encodeURIComponent(JSON.stringify(newData));
	return window.open(url);
};

interface CreateInvoicePDFProps {
	address: string;
	pnr: string;
}

const createInvoicePDF = async (data: CreateInvoicePDFProps) => {
	const newData = {
		...data,
		...{
			url: APP_API
		}
	};
	const url = PDF_API + api.PDF.INVOICE_PDF + '?data=' + encodeURIComponent(JSON.stringify(newData));
	return window.open(url);
};

const service = {
	createReceiptPDF,
	createTicketPDF,
	createInvoicePDF
};

export default service;
