import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getHotels = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOTELS);
	return response.data;
};

const getHotelById = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.HOTELS + `/${id}`);
	return response.data;
};

const getHotelCategories = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_HOTEL_CATEGORY);
	return response.data;
};

const getHotelProviders = async () => {
	const response = await axiosClient.get(api.PRIVATE.HOTEL_PROVIDERS);
	return response.data;
};

interface ServiceTypes {
	name: string;
	region: string;
	address: string;
	category: string;
	services: string;
	description: string;
	notes: string;
	country: string;
	currency: string;
	markupAdultType: string;
	markupAdultAmount: number;
	markupChildType: string;
	markupChildAmount: number;
	markupInfType: string;
	markupInfAmount: number;
}

/**
 * Create
 */
const createHotel = async (data: ServiceTypes) => {
	const response = await axiosClient.post(api.PRIVATE.HOTELS, data);
	return response.data;
};

/**
 * Update
 */
const updateHotel = async (data: ServiceTypes, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.HOTELS + '/' + id, data);
	return response.data;
};

/**
 * Delete
 */

const deleteHotel = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.HOTELS + '/' + id);
	return response.data;
};

const service = {
	getHotels,
	getHotelProviders,
	getHotelCategories,
	getHotelById,
	createHotel,
	updateHotel,
	deleteHotel
};

export default service;
