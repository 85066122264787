import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface HistoryProps {
	start: string;
	end: string;
}

const getHistory = async (data: HistoryProps) => {
	const response = await axiosClient.post(api.PRIVATE.GET_HISTORY, data);
	return response.data;
};

const service = {
	getHistory
};

export default service;
