import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

const getHotelRoomMeal = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_HOTEL_ROOM_MEAL);
	return response.data;
};

const getHotelRooms = async (id: string) => {
	const response = await axiosClient.get(api.PRIVATE.ROOMS + `/${id}`);
	return response.data;
};

const getRoomCategory = async () => {
	const response = await axiosClient.get(api.PRIVATE.GET_ROOM_CATEGORY);
	return response.data;
};

interface ServiceTypes {
	hotelId: string;
	category: string;
	size: number;
	periodStart: string;
	periodEnd: string;
	price1ADLT: number;
	price1ADLT_1INF: number;
	price1ADLT_1CHD: number;
	price1ADLT_1CHD_1INF: number;
	price1ADLT_2CHD: number;
	price1ADLT_2CHD_1INF: number;
	price2ADLT: number;
	price2ADLT_1INF: number;
	price2ADLT_1CHD: number;
	price2ADLT_1CHD_1INF: number;
	price2ADLT_2CHD: number;
	price2ADLT_2CHD_1INF: number;
	price3ADLT: number;
	price3ADLT_1INF: number;
	price3ADLT_1CHD: number;
	price3ADLT_1CHD_1INF: number;
	price3ADLT_2CHD: number;
	price3ADLT_2CHD_1INF: number;
	status: string;
}

/**
 * Create
 */

const createRoom = async (data: ServiceTypes) => {
	const response = await axiosClient.post(api.PRIVATE.ROOMS, data);
	return response.data;
};

/**
 * Update
 */

const updateRoom = async (data: ServiceTypes, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.ROOMS + '/' + id, data);
	return response.data;
};

const updateRoomCategoryMealType = async (data: ServiceTypes, id: string) => {
	const response = await axiosClient.put(api.PRIVATE.GET_ROOM_CATEGORY + '/' + id, data);
	return response.data;
};
/**
 * Delete
 */

const deleteRoom = async (id: string) => {
	const response = await axiosClient.delete(api.PRIVATE.ROOMS + '/' + id);
	return response.data;
};

const service = {
	getHotelRoomMeal,
	getHotelRooms,
	updateRoom,
	createRoom,
	deleteRoom,
	getRoomCategory,
	updateRoomCategoryMealType
};

export default service;
