import axiosClient from 'utils/axiosClient';
import api from 'api';

interface LoginParams {
	email: string;
	password: string;
}

const login = async (data: LoginParams) => {
	const response = await axiosClient.post(api.PUBLIC.LOGIN, data);
	return response.data;
};

interface SetPasswordParams {
	user_id: string;
	password: string;
	c_password: string;
}

const setPassword = async (data: SetPasswordParams) => {
	const response = await axiosClient.put(api.PUBLIC.SET_PASSWORD_API, data);
	return response.data;
};

const checkValidSetPassword = async (id: string) => {
	const response = await axiosClient.get(api.PUBLIC.CHECK_VALID_SET_PASSWORD_API, {
		params: {
			user_id: id
		}
	});
	return response.data;
};

export interface RegisterUserParams {
	ceoNameSurname: string;
	companyName: string;
	contactNameSurname: string;
	businessName: string;
	phone: string;
	mobilePhone: string;
	vat: string;
	country: string | null;
	city: string;
	email: string;
	password: string;
	address: string;
	confirmPassword: string;
	checkbox: boolean;
}

export interface RegisterUserParamsErrors {
	ceoNameSurname: string;
	companyName: string;
	contactNameSurname: string;
	businessName: string;
	phone: string;
	mobilePhone: string;
	vat: string;
	country: string;
	city: string;
	email: string;
	password: string;
	address: string;
	confirmPassword: string;
	checkbox: string;
}

const registerUser = async (data: RegisterUserParams) => {
	const response = await axiosClient.post(api.PUBLIC.REGISTER, data);
	return response.data;
};

interface ResetPasswordParams {
	email: string;
}

const resetPassword = async (data: ResetPasswordParams) => {
	const response = await axiosClient.post(api.PUBLIC.RESET_PASSWORD, data);
	return response.data;
};

interface NewPasswordParams {
	password: string;
	userId: string;
}

const setNewPassword = async (data: NewPasswordParams) => {
	const response = await axiosClient.post(api.PUBLIC.SET_NEW_PASSWORD + data.userId, {
		password: data.password
	});
	return response.data;
};

const service = {
	login,
	setPassword,
	checkValidSetPassword,
	setNewPassword,
	registerUser,
	resetPassword
};

export default service;
