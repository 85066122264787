import axiosClient from 'utils/axiosClient';
import api from 'api';

/**
 * Get
 */

interface ExchangeProps {
	startDate: string;
	endDate: string;
}

const getExchangeRange = async (data: ExchangeProps) => {
	const response = await axiosClient.post(api.PUBLIC.GET_EXCHANGE_RATE, data);
	return response.data;
};

const service = {
	getExchangeRange
};

export default service;
