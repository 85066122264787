// API without token
const LOGIN = '/user/login';
const GET_USER_GROUPS_LIST = '/api/filter/userGroup';
const CREATE_USER = '/api/auth/register';
const SET_PASSWORD_API = '/api/auth/setPassword';
const CHECK_VALID_SET_PASSWORD_API = '/api/auth/checkChangePasswordPage';
const GET_EXCHANGE_RATE = '/exchange/rate-by-range';
const REGISTER = '/user/register';
const RESET_PASSWORD = '/user/forgot-password';
const SET_NEW_PASSWORD = '/user/forgot-password/reset/';

const PUBLIC_API = {
	LOGIN,
	GET_USER_GROUPS_LIST,
	CREATE_USER,
	SET_PASSWORD_API,
	CHECK_VALID_SET_PASSWORD_API,
	GET_EXCHANGE_RATE,
	REGISTER,
	RESET_PASSWORD,
	SET_NEW_PASSWORD
};

export default PUBLIC_API;
