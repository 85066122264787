import {Typography, makeStyles, Divider, Link} from '@material-ui/core';
import packageJson from '../../../package.json';
import phone from 'assets/icons/phone.jpg';
import location from 'assets/icons/location.jpg';
import email from 'assets/icons/email.jpg';
import moment from 'moment';
import {Social} from 'components/AppBar/AppBar';

function Copyright() {
	return (
		<Typography variant="body2" align="left" style={{fontSize: 14, margin: '0 auto', maxWidth: 1300}}>
			&copy;{moment().year()} Festa Tour. All rights reserved. v{packageJson.version}
		</Typography>
	);
}

const useStyles = makeStyles(theme => ({
	footer: {
		padding: theme.spacing(2),
		marginTop: 50,

		backgroundColor: theme.palette.primary.main,
		color: '#FFFFFF'
	},
	footInfo: {
		border: '1px solid #fff',
		marginTop: 40,
		padding: '20px 35px',
		margin: '0 auto',
		maxWidth: 1300,
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {flexDirection: 'column'}
	},
	icColor: {color: theme.palette.primary.main, fontSize: 32},
	icBox: {
		background: '#fff',
		height: 60,
		width: 60,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	hr: {
		maxWidth: 1290,
		backgroundColor: 'rgba(255,255,255,0.4)',
		margin: '25px auto 40px auto'
	},
	inf: {
		marginLeft: 15,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	title: {
		fontWeight: 600
	},
	footerinf: {display: 'flex', cursor: 'pointer', [theme.breakpoints.down('sm')]: {margin: '10px 0'}}
}));

const footerInfo = [
	{title: 'Telephone', value: '+374 15 555551', img: phone, link: 'tel:+37415555551', target: '_parent'},
	{
		title: 'E-mail',
		value: 'tickets@festatour.am',
		img: email,
		link: 'mailto:tickets@festatour.am',
		target: '_parent'
	},
	{
		title: 'Address',
		value: '37-54, Mashtots Ave., Yerevan, RA',
		img: location,
		link: 'https://www.google.com/maps/place/%D0%A4%D0%B5%D1%81%D1%82%D0%B0+%D0%A2%D1%83%D1%80/@40.1865709,44.513161,19.25z/data=!4m14!1m7!3m6!1s0x406abd1dea4dd029:0x81978c77ae2e5831!2z0KTQtdGB0YLQsCDQotGD0YA!8m2!3d40.186663!4d44.513287!16s%2Fg%2F1v9l97zg!3m5!1s0x406abd1dea4dd029:0x81978c77ae2e5831!8m2!3d40.186663!4d44.513287!16s%2Fg%2F1v9l97zg',
		target: '_blank'
	}
];

const Footer = () => {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<div className={classes.footInfo}>
				{footerInfo.map((el: any) => (
					<Link href={el.link} underline="none" color="inherit" target={el.target} key={el.value}>
						<div className={classes.footerinf}>
							<img src={el.img} alt="" width={60} height={60} />
							<div className={classes.inf}>
								<div className={classes.title}>{el.title}</div>
								<div>{el.value}</div>
							</div>
						</div>
					</Link>
				))}
			</div>
			<div style={{maxWidth: 1300, margin: '25px auto'}}>
				<Social />
			</div>
			<Divider className={classes.hr} />
			<Copyright />
		</footer>
	);
};

export default Footer;
