import {useState, useEffect} from 'react';
import {Container, Typography, Button, Avatar, CircularProgress, Paper, makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import services from 'services';
import queryString from 'query-string';
import type {RouteComponentProps} from 'react-router-dom';
import Loading from 'components/Loading';
import SuccessAlert from 'components/alerts/SuccessAlert';
import ErrorAlert from 'components/alerts/ErrorAlert';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.up('lg')]: {
			marginTop: theme.spacing(8)
		}
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		'& label': {
			fontSize: '0.9rem'
		}
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	alertContainer: {
		width: '100%'
	},
	errorAlert: {
		marginTop: theme.spacing(3)
	}
}));

interface InputErrorsTypes {
	password: string;
	c_password: string;
}

interface UserTypes {
	password: string;
	c_password: string;
	isSubmitting: boolean;
	errorMessage: string | null;
	inputErrors: InputErrorsTypes | null;
}

interface PasswordExpairTypes {
	warningText: string | null;
	successText: string | null;
	loading: boolean;
}

const PasswordSetting = ({location}: RouteComponentProps) => {
	const classes = useStyles();
	const [user, setUser] = useState<UserTypes>({
		password: '',
		c_password: '',
		isSubmitting: false,
		errorMessage: null,
		inputErrors: null
	});
	const [passwordExpair, setPasswordExpair] = useState<PasswordExpairTypes>({
		warningText: null,
		successText: null,
		loading: true
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUser(prevState => ({
			...prevState,
			[event.target.name]: event.target.value
		}));
	};

	const handleSubmit = () => {
		const id = queryString.parse(location.search).user_id as string;
		setUser({
			...user,
			isSubmitting: true,
			errorMessage: null
		});
		services.PUBLIC_SERVICES.AUTH.setPassword({
			user_id: id,
			password: user.password,
			c_password: user.c_password
		})
			.then(res => {
				setPasswordExpair({
					...passwordExpair,
					successText: res.message
				});
			})
			.catch(err => {
				if (err.message) {
					setUser({
						...user,
						isSubmitting: false,
						errorMessage: err.message
					});
				} else {
					setUser({
						...user,
						isSubmitting: false,
						inputErrors: err
					});
				}
			});
	};

	useEffect(() => {
		ValidatorForm.addValidationRule('isPasswordMatch', (value: string) => {
			if (value !== user.password) {
				return false;
			}
			return true;
		});

		return () => {
			ValidatorForm.removeValidationRule('isPasswordMatch');
		};
	}, [user.password]);

	useEffect(() => {
		const warningText = 'Այս էջը այլևս հասանելի չէ';
		const id = queryString.parse(location.search).user_id as string;
		if (id) {
			services.PUBLIC_SERVICES.AUTH.checkValidSetPassword(id)
				.then(() => {
					setPasswordExpair({
						warningText: null,
						successText: null,
						loading: false
					});
				})
				.catch(err => {
					setPasswordExpair({
						warningText: err.message || warningText,
						successText: null,
						loading: false
					});
				});
		} else {
			setPasswordExpair({
				warningText: warningText,
				successText: null,
				loading: false
			});
		}
	}, [location.search]);

	if (passwordExpair.loading) {
		return <Loading />;
	}

	return (
		<Container maxWidth="xs">
			<Paper className={classes.paper}>
				{passwordExpair.successText ? (
					<SuccessAlert text={passwordExpair.successText} />
				) : passwordExpair.warningText ? (
					<ErrorAlert text={passwordExpair.warningText} />
				) : (
					<>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Գաղտնաբառի տեղադրում
						</Typography>
						{user.errorMessage && (
							<div className={classes.alertContainer}>
								<Alert severity="error" className={classes.errorAlert}>
									{user.errorMessage}
								</Alert>
							</div>
						)}
						<ValidatorForm className={classes.form} onSubmit={handleSubmit}>
							<TextValidator
								error={!!user.inputErrors?.password}
								helperText={user.inputErrors?.password}
								variant="outlined"
								margin="dense"
								fullWidth
								onChange={handleChange}
								label="Գաղտնաբառ"
								name="password"
								type="password"
								validators={['required', 'minStringLength:6']}
								errorMessages={['Դաշտը պարտադիր է', 'Նվազագույնը 6 նիշ']}
								value={user.password}
							/>
							<TextValidator
								error={!!user.inputErrors?.c_password}
								helperText={user.inputErrors?.c_password}
								variant="outlined"
								margin="dense"
								fullWidth
								onChange={handleChange}
								label="Հաստատել գաղտնաբառը"
								name="c_password"
								type="password"
								validators={['isPasswordMatch', 'required']}
								errorMessages={['password mismatch', 'Դաշտը պարտադիր է']}
								value={user.c_password}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={user.isSubmitting}>
								{user.isSubmitting ? <CircularProgress size={20} /> : 'Հաստատել'}
							</Button>
						</ValidatorForm>
					</>
				)}
			</Paper>
		</Container>
	);
};

export default PasswordSetting;
